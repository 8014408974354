import React from "react";
import Tittle from "../Common/Tittle";

import Member from "./Member";

const Members = () => {
  const people = [
    {
      name: "Andru Estes",
      role: "Founder",
      imageUrl: require("../../assets/Images/coach_andru.png"),
      bio:
        "Andru has been working in the IT industry for over a decade. He has experience ranging from Systems Administration, to Enterprise Cloud Architecture, and most recently as a Training Architect teaching people how to cloud. His diverse backgound has helped him excel within the tech space, and now he has reached a point where he can finally help others break into the industry. ",
      twitterUrl: "https://twitter.com/andru_estes",
      linkedinUrl: "https://linkedin.com/in/robertandruestes",
    },
    {
      name: "Mallory Estes",
      role: "Co-founder & Project Management",
      imageUrl: require("../../assets/Images/coach_mallory.png"),
      bio:
        "Mallory has been involved with IT for 11 years. She started off in the Quality Engineering space, moved her way into Delivery Management, and finally found her calling in the Product and Project Management space. Plain and simple, she get's sh*t done.",
      linkedinUrl: "https://linkedin.com/in/mallory-estes",
    },
    // More people...
  ];
  return (
    <section className="customContainer">
      <Tittle title={"Our Team Members"} />
      {people.length === 1 ? (
        <div className="max-w-xl m-auto">
          <Member person={people[0]} />
        </div>
      ) : people.length === 2 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 w-90 m-auto gap-8">
          {people.map((person, index) => (
            <Member key={index} person={person} />
          ))}
        </div>
      ) : (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
          {people.map((person, index) => (
            <Member key={index} person={person} />
          ))}
        </div>
      )}
    </section>
  );
};

export default Members;
