import React from "react";
import AboutUs from "../../components/AboutUs/AboutUs";
import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Members from "../../components/Members/Members";
import Message from "../../components/Message/Message";

const Home = () => {
  return (
    <>
      <Header />
      <Message />
      <AboutUs />
      <Members />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Home;
