import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="linearBg -mt-24">
      <div className="customContainer">
        <h2 className="text-white text-center py-8">
          &copy; {year} - <span>All rights reserved By Quick To Cloud</span>
        </h2>
      </div>
    </footer>
  );
};

export default Footer;
