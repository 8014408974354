import Home from "./pages/Home/Home";

import "./App.css";
import "react-vertical-timeline-component/style.min.css";

function App() {
  return (
    <main className="w-full bg-black">
      <Home />
    </main>
  );
}

export default App;
