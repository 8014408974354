import React from "react";
import logo from "../../assets/Images/logoCroped.png";

const Header = () => {
  return (
    <header className="border-b-2 border-primary">
      <div className="customContainer py-4 flex justify-center md:justify-start">
        <img src={logo} className="w-60" alt="" />
      </div>
    </header>
  );
};

export default Header;
