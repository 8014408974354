import React from "react";
// import Facebook from "../Icons/Facebook";
import Github from "../Icons/Github";
// import Twitter from "../Icons/Twitter";
import LinkedIn from "../Icons/LinkedIn";

const ContactUs = () => {
  return (
    <section className="clipPathBg my-20">
      <div className="customContainer md:py-48 pt-48 flex flex-col items-center">
        <h2 className="text-white text-4xl text-center">Connect With Us!</h2>
        <a
          target={"_blank"}
          rel="noopener noreferrer"
          href="mailto:andru.estes@quicktocloud.io"
          className="my-8"
        >
          <button className="secondaryActionBtn">Contact Us</button>
        </a>
        <div className="flex items-center">
          <a
            href="https://www.linkedin.com/company/quicktocloud"
            className="text-linkedin"
          >
            <LinkedIn />
          </a>
          {/* Commenting out until we put together a company twitter. */}
          {/* <a href="www.twitter.com/andru_estes" className="text-twitter">
            <Twitter />
          </a> */}
          <a href="https://github.com/QuickToCloud-LLC" className="text-github">
            <Github />
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
