import React from "react";
import LinkedIn from "../Icons/LinkedIn";
import Twitter from "../Icons/Twitter";

const Member = ({ person }) => {
  return (
    <>
      <div className="space-y-4 border-2 border-primary rounded-lg">
        <div className="aspect-w-3 aspect-h-2">
          <img
            className="object-cover shadow-lg rounded-lg"
            src={person.imageUrl}
            alt=""
          />
        </div>
        <div className="p-4">
          <div className="text-lg leading-6 font-medium space-y-1">
            <h3 className="text-primary text-2xl">{person.name}</h3>
            <p className="text-secondary">{person.role}</p>
          </div>
          <div className="text-lg py-4 ">
            <p className="text-white">{person.bio}</p>
          </div>

          <ul className="flex items-center space-x-5">
            <li>
              <a
                href={person.twitterUrl}
                className="text-secondary hover:text-gray-500"
              >
                <span className="sr-only">Twitter</span>
                <Twitter />
              </a>
            </li>
            <li>
              <a
                href={person.linkedinUrl}
                className="text-linkedIn hover:text-gray-500"
              >
                <span className="sr-only">LinkedIn</span>
                <LinkedIn />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Member;
