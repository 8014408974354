import React from "react";

const Message = () => {
  return (
    <section className="mx-auto flex max-w-7xl items-center justify-center flex-col py-32 px-4 md:py-36">
      <div className="text-center">
        <h2 className="font-gotham text-8xl tracking-tight text-white md:text-8xl">
          <span className="block"> The cloud starts now!</span>
        </h2>
        <div className="mt-20 flex w-full flex-col items-center justify-center">
          <div className="h-1 w-20 bg-white"></div>
          {/* Removing for now 👇🏼 */}
          {/* <h2 className="mt-10 font-raleway text-4xl font-semibold uppercase tracking-widest text-white">
            Author
          </h2> */}
        </div>
      </div>
      <div className="text-white md:p-12 p-0">
        <h2 className="text-4xl py-8">
          Are you ready to jumpstart your career into the{" "}
          <span className="text-secondary">cloud</span>?
        </h2>
        <p className="text-lg">
          Quick To Cloud LLC is proud to offer a premium, self-paced bootcamp
          program utlizing some of the best training platforms in the world. Our
          expert coaches will help you gain actual hands-on experience to help
          break into the cloud industry and earn a large salary! Estimated to
          reach a market value of $1.25 trillion by 2028, cloud computing isn't
          going anywhere anytime soon. In fact, it's growing at an unprecedented
          rate.
          <br></br>
          <br></br>
          According to Glassdoor, the national average salary for a Cloud
          Engineer is{" "}
          <span className="text-primary text-4xl tracking-tight md:text-4xl">
            {" "}
            $116,609.
          </span>
          <br></br>
          <br></br>
          Yes, you read that right! So, what are you waiting for? Today can be
          the day you take the first steps to change your life and career
          forever.
        </p>
        {/* Removing 👇🏼 for now. */}
        {/* <div className="py-4">
          <button className="primaryActionBtn">Contact Us</button>
        </div> */}
      </div>
    </section>
  );
};

export default Message;
