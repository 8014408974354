import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "./AboutUs.css";

const AboutUs = () => {
  const timeline = [
    {
      title: "Who?",
      description:
        "This bootcamp is meant to help anyone just starting to transition into the IT world. There is absolutely ZERO experience required. We will help you build the skillsets, from the ground up, that are in high demand within the industry.",
      icon: (
        <svg className="w-16 h-auto" viewBox="0 0 448 512" fill="#00ddf4">
          <path
            className="icon"
            d="M128 152v-32c0-4.4 3.6-8 8-8h208c4.4 0 8 3.6 8 8v32c0 4.4-3.6 8-8 8H136c-4.4 0-8-3.6-8-8zm8 88h208c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8H136c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8zm299.1 159.7c-4.2 13-4.2 51.6 0 64.6 7.3 1.4 12.9 7.9 12.9 15.7v16c0 8.8-7.2 16-16 16H80c-44.2 0-80-35.8-80-80V80C0 35.8 35.8 0 80 0h352c8.8 0 16 7.2 16 16v368c0 7.8-5.5 14.2-12.9 15.7zm-41.1.3H80c-17.6 0-32 14.4-32 32 0 17.7 14.3 32 32 32h314c-2.7-17.3-2.7-46.7 0-64zm6-352H80c-17.7 0-32 14.3-32 32v278.7c9.8-4.3 20.6-6.7 32-6.7h320V48z"
          ></path>
        </svg>
      ),
    },
    {
      title: "What You'll Learn",
      description:
        "We will help guide you through the process of learning the key fundamentals of getting a cloud position. You will learn everything from the basics of Linux operating systems, to the more advanced topics involving DevOps methodologies and cloud architecture design.",
      icon: (
        <svg className="w-16 h-auto" viewBox="0 0 448 512" fill="#00ddf4">
          <path
            className="icon"
            d="M437.2 403.5L320 215V48h20c6.6 0 12-5.4 12-12V12c0-6.6-5.4-12-12-12H108c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h20v167L10.8 403.5C-18.5 450.6 15.3 512 70.9 512h306.2c55.7 0 89.4-61.5 60.1-108.5zM377.1 464H70.9c-18.1 0-28.7-20.1-19.3-35.2l117.2-188.5c4.7-7.6 7.2-16.4 7.2-25.3V48h96v167c0 9 2.5 17.7 7.2 25.3l117.2 188.5c9.4 15.1-1.1 35.2-19.3 35.2z"
          ></path>
        </svg>
      ),
    },
    {
      title: "How You'll Learn",
      description:
        "You will learn on a self-paced schedule using some of the industry's best training platforms. Our coaches will be there to help you every step of the way, answering any questions that may come up. You'll learn how to create and manage Git repositories, write blog posts, and showcase your skillsets to employers via publicly viewable projects built within major public cloud providers.",
      icon: (
        <svg className="w-16 h-auto" viewBox="0 0 640 512" fill="#00ddf4">
          <path
            className="icon"
            d="M543.7 200.1C539.7 142.1 491.4 96 432 96c-7.6 0-15.1.8-22.4 2.3C377.7 58.3 328.1 32 272 32c-84.6 0-155.5 59.7-172.3 139.8C39.9 196.1 0 254.4 0 320c0 88.4 71.6 160 160 160h336c79.5 0 144-64.5 144-144 0-61.8-39.2-115.8-96.3-135.9zM496 432H160c-61.9 0-112-50.1-112-112 0-56.4 41.7-103.1 96-110.9V208c0-70.7 57.3-128 128-128 53.5 0 99.3 32.8 118.4 79.4 11.2-9.6 25.7-15.4 41.6-15.4 35.3 0 64 28.7 64 64 0 11.8-3.2 22.9-8.8 32.4 2.9-.3 5.9-.4 8.8-.4 53 0 96 43 96 96s-43 96-96 96z"
          ></path>
        </svg>
      ),
    },
    {
      title: "Employment Partners",
      description:
        "We partner with some of the best tech recruitment firms in the industry today! They know what is in demand and how to best present your skillsets to major companies.  By closely partnering with our recruiting partners, you will be better prepared for landing the dream job that you have been working so hard to achieve.",
      icon: (
        <svg className="w-16 h-auto" viewBox="0 0 640 512" fill="#00ddf4">
          <path
            className="icon"
            d="M543.7 200.1C539.7 142.1 491.4 96 432 96c-7.6 0-15.1.8-22.4 2.3C377.7 58.3 328.1 32 272 32c-84.6 0-155.5 59.7-172.3 139.8C39.9 196.1 0 254.4 0 320c0 88.4 71.6 160 160 160h336c79.5 0 144-64.5 144-144 0-61.8-39.2-115.8-96.3-135.9zM496 432H160c-61.9 0-112-50.1-112-112 0-56.4 41.7-103.1 96-110.9V208c0-70.7 57.3-128 128-128 53.5 0 99.3 32.8 118.4 79.4 11.2-9.6 25.7-15.4 41.6-15.4 35.3 0 64 28.7 64 64 0 11.8-3.2 22.9-8.8 32.4 2.9-.3 5.9-.4 8.8-.4 53 0 96 43 96 96s-43 96-96 96z"
          ></path>
        </svg>
      ),
    },
  ];
  return (
    <section className="aboutUsContainer pb-32">
      <div className="customContainer">
        <VerticalTimeline lineColor="#F8F9FE">
          {timeline.map((item, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{
                boxShadow: "none",
                backgroundColor: "transparent",
                background:
                  "linear-gradient(360deg, #0b0f1e 0%, rgba(11, 15, 30, 0.62) 70.26%)",
                borderRadius: "16px",
                border: "1px solid #00ddf4",
                position: "relative",
                color: "#fff",
              }}
              contentArrowStyle={{
                display: "none",
              }}
              iconStyle={{
                background: "#f8f9fe",
                boxShadow: "0px 0px 25px rgba(86, 184, 255, 0.7)",
              }}
            >
              <div className="flex justify-between items-center">
                <h3 className="vertical-timeline-element-title  text-3xl">
                  {item.title}
                </h3>

                {item.icon}
              </div>
              <p>{item.description}</p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </section>
  );
};

export default AboutUs;
