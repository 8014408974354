import React from "react";

const Tittle = ({ title }) => {
  return (
    <>
      <div className="flex justify-center items-center flex-col py-16">
        <h2 className="text-center gothamMedium text-5xl pb-6 text-primary uppercase">
          {title}
        </h2>
        <div className="h-1 w-20 bg-white"></div>
      </div>
    </>
  );
};

export default Tittle;
